<template>
  <div class="m-role-edit">
    <b-notification
      v-if="savingError"
      type="is-danger"
      has-icon
      :aria-close-label="$t('generic.closeMessageButton')"
      role="alert"
    >
      {{ $t('settings.savingError') }}
    </b-notification>
    <b-loading :active.sync="loading"></b-loading>
    <h1 class="title is-1">{{ heading }}</h1>
    <div class="columns">
      <div class="column is-half">
        <div class="container ra-form-section">
          <b-field :label="$t('settings.roleName')" :type="nameType" :message="nameValidationMessage">
            <b-input v-model="name" :maxlength="limits.name" @input="resetValidation"></b-input>
          </b-field>

          <b-field :label="$t('settings.roleDescription')">
            <b-input type="textarea" v-model="description" :maxlength="limits.description"></b-input>
          </b-field>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <div class="field is-grouped">
          <div class="control">
            <b-button @click="cancel" type="is-secondary">{{ $t('generic.cancelButton') }}</b-button>
          </div>
          <div class="control">
            <b-button :disabled="loading" @click="dispatch" type="is-primary" :loading="saving">{{
              $t('generic.saveButton')
            }}</b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as namespaces from '../../../store/namespaces';
import * as actions from '../../../store/actions';
import logger from '../../../utils/logger';
import conversions from '../../../utils/conversions';

export default {
  name: 'RoleEdit',
  props: {
    roleId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      limits: {
        name: 50,
        description: 200
      },
      loading: false,
      loadingError: false,
      saving: false,
      savingError: false,
      name: '',
      nameValidationMessage: '',
      nameType: '',
      description: ''
    };
  },
  computed: {
    rolesRegistry() {
      if (this.$store.state.system.registries) {
        return conversions.convertToMap(this.$store.state.system.registries.roles);
      }
      return [];
    },
    isEdit() {
      return !!this.roleId;
    },
    heading() {
      if (this.isEdit) {
        return this.$t('settings.editRoleHeading');
      } else {
        return this.$t('settings.createRoleHeading');
      }
    }
  },
  methods: {
    createDataPayload() {
      const data = {
        name: this.name,
        description: this.description
      };
      if (this.isEdit) {
        return { id: this.roleId, data };
      } else {
        return data;
      }
    },
    loadRole() {
      if (!this.rolesRegistry) {
        return null;
      }
      let role = this.rolesRegistry[this.roleId];
      this.name = role.name;
      this.description = role.description;
    },
    isFormValid() {
      if (this.name === '') {
        (this.nameType = 'is-danger'), (this.nameValidationMessage = this.$t('settings.emptyNameError'));
        return false;
      }
      return true;
    },
    resetValidation() {
      this.nameType = '';
      this.nameValidationMessage = '';
    },
    dispatch() {
      if (!this.isFormValid()) {
        return false;
      }
      this.saving = true;
      this.savingError = false;
      let payload = this.createDataPayload();
      this.$store
        .dispatch(namespaces.SYSTEM + (this.isEdit ? actions.EDIT_ROLE : actions.ADD_ROLE), payload)
        .then(() => {
          this.$router.replace({ name: 'settings-roles-list' });
        })
        .catch(error => {
          this.savingError = true;
          logger.error(error);
        })
        .finally(() => {
          this.saving = false;
        });
    },
    cancel() {
      this.name = '';
      this.description = '';
      this.$router.go(-1);
    }
  },
  mounted() {
    this.loadRole();
  },
  watch: {
    roleId() {
      this.loadRole();
    }
  }
};
</script>
